import { render, staticRenderFns } from "./CompetenciesSubcategoriesList.vue?vue&type=template&id=89885af6&scoped=true"
import script from "./CompetenciesSubcategoriesList.vue?vue&type=script&lang=js"
export * from "./CompetenciesSubcategoriesList.vue?vue&type=script&lang=js"
import style0 from "./CompetenciesSubcategoriesList.vue?vue&type=style&index=0&id=89885af6&prod&lang=scss"
import style1 from "./CompetenciesSubcategoriesList.vue?vue&type=style&index=1&id=89885af6&prod&lang=scss&scoped=true"
import style2 from "./CompetenciesSubcategoriesList.vue?vue&type=style&index=2&id=89885af6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89885af6",
  null
  
)

export default component.exports