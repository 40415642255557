<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <b-card-header class="pb-50">
        <b-button
          class="btn-icon"
          variant="flat"
          style="padding: 0"
          @click="visible = !visible"
        >
          <h4 :class="{ 'mb-1': !visible }">
            <feather-icon v-if="!visible" size="20" icon="ChevronDownIcon" />
            <feather-icon v-else size="20" icon="ChevronUpIcon" />
            <span>Lista de competências</span>
          </h4>
        </b-button>
        <b-card-text v-if="visible" class="mb-1">
          <b-badge variant="primary" class="mr-1">
            <span>Arraste para o lado</span>
            <feather-icon icon="ChevronsRightIcon" class="ml-25" />
          </b-badge>
        </b-card-text>
        <b-card-text class="mb-1">
          <b-button
              variant="primary"
              class="ml-2"
              size="sm"
              @click="handleRowsClicked"
          >
            Abrir todos os cards
          </b-button>
        </b-card-text>
      </b-card-header>
      <b-table
        v-if="visible" 
        hover
        class="position-relative text-center lead-table"
        :items="tableItems"
        :fields="tableFields"
        responsive
        show-empty
        empty-text="Não foram encontrados competências para ser exibidas"
        :busy="loading"
        :tbody-tr-class="rowClass"
        @row-clicked="handleRowClicked"
      >
        <template #cell(total)="data">
            <div class="text-wrap">
              <span class="align-text-top">{{
                data.item.total | toCurrency
              }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { toCurrency } from "@/helpers/converters.js";
import {GET_COMPETENCIES_SUBCATEGORIES_EXPENSES, MUTATE_COMPETENCIES_SUBCATEGORIES_EXPENSES} from "../store/types";

export default {
  components: {},
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      visible: false,
      tableItems: [],
      tableFields: [],
    };
  },
  computed: {
    ...mapGetters({
      competenciesExpenses: types.COMPETENCIES_EXPENSES,
      expenseSearchYear: types.EXPENSES_SEARCH_YEAR
    }),
    costCenters: function () {
      return this.competenciesExpenses?.cost_centers || {};
    },
  },
  methods: {
    ...mapActions({
      getCompetenciesExpenses: types.GET_COMPETENCIES_EXPENSES,
    }),
    transformCostCenters() {
      const months = Object.keys(this.costCenters);
      const names = this.costCenters[months[0]].map((item) => item.name);

      this.tableFields = [
        { key: "name", label: "Centro de custo" },
        ...months.map((month) => ({ key: month, label: month })),
      ];
      this.tableFields.push({ key: "total", label: "Total" });

      this.tableItems = names.map((name) => {
        const item = { name, total: 0 };
        months.forEach((month) => {
          const costCenter = this.costCenters[month].find(
            (cc) => cc.name === name
          );
          item[month] = costCenter
            ? toCurrency(costCenter.amount)
            : toCurrency(0);
          item.total += costCenter ? costCenter.amount : 0;
        });
        return item;
      });

      const totalRow = { name: "Total", total: 0 };
      months.forEach((month) => {
        totalRow[month] = toCurrency(
          this.costCenters[month].reduce((sum, cc) => sum + cc.amount, 0)
        );
        totalRow["total"] += this.costCenters[month].reduce(
          (sum, cc) => sum + cc.amount, 0
        );
      });
      this.tableItems.push(totalRow);
    },

    async getData(){
      this.loading = true;
      try {
        await this.getCompetenciesExpenses(this.expenseSearchYear);
        this.transformCostCenters();
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar a lista de competências. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loading = false;
      }
    },

    rowClass(item, type) {
      let colorClass = "";
      if (!item || type !== "row") {
        return;
      }
      if (item.name === "Total") {
        if (this.skin === "dark") {
          colorClass = "total-dark-color";
        } else if (this.skin === "light") {
          colorClass = "table-primary";
        }
        return colorClass;
      }
    },
    handleRowsClicked(item) {
      if (item.name !== "Total") {
        this.$emit("cost-center-rows-clicked");
      }
    },
    handleRowClicked(item) {
      if (item.name !== "Total") {
        this.$emit("cost-center-clicked", item.name);
      }
    },
  },
};
</script>

<style lang="scss">
.total-dark-color {
  background-color: #ff7f50;

  &:hover {
    background-color: rgba(255, 127, 80, 0.8) !important;
  }

  td {
    color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>

.per-page-selector {
  width: 90px;
}

.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}

input[type="datetime-local"] {
  width: 180px;
}

.lead-table .phone {
  color: #e3e3e3;
}

.dark-layout .lead-table .phone {
  color: #676d7d;
}

.lead-table .name-content {
  width: 170px;
  display: flex;
  justify-content: center;
}

.lead-table .name-content .name {
  width: auto;
}

.lead-table .name-content .name:hover {
  background-color: #f26237;
}

.lead-table .name-content .whats {
  width: 27px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.lead-table .name-content .whats:hover {
  background-color: #f26237;
}

.lead-table .btn-group .btn {
  border: unset !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

table #buttons {
  width: 40px;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.lead-table thead th {
  white-space: nowrap !important;
}
</style>
